import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Multibrand from 'pages/Multibrand';
import Convida from './pages/convida';
import Setup from './pages/Setup';

const RoutesWrapper: React.FC = () => {
  return (
    <Routes>
      <Route path="/setup" element={<Setup />} />
      <Route path="/convida" element={<Convida />} />
      <Route path="/multimarca" element={<Multibrand />} />
    </Routes>
  );
};

export default RoutesWrapper;
