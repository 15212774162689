import React from 'react';

import {
  Back,
  Container,
  Logo,
  Link,
  Nav,
  Social,
  Menu,
  Label,
} from './styles';

type bg = 'purple' | 'white';
interface Props {
  bgContainer?: bg;
}

const HeaderB2B: React.FC<Props> = ({ bgContainer = 'purple', children }) => {
  return (
    <Back bgContainer={bgContainer}>
      <Container>
        <Link href="https://negocios.houpa.app">
          <Logo
            bgContainer={bgContainer}
            className="icon icon-logo-houpa-business"
          />
        </Link>
        <Menu bgContainer={bgContainer}>
          <Nav>
            <input id="menu-hamburguer" type="checkbox" />

            <Label htmlFor="menu-hamburguer">
              <div className="menu">
                <span className="hamburguer" />
              </div>
            </Label>

            <ul id="nav-menu">{children}</ul>
          </Nav>
        </Menu>

        <Social bgContainer={bgContainer}>
          <Link href="https://www.instagram.com/houpa.app/" target="_blank">
            <i className="icon icon-instagram" />
          </Link>
          <Link href="https://www.facebook.com/houpaapp/" target="_blank">
            <i className="icon icon-facebook" />
          </Link>
          <Link href="https://www.linkedin.com/company/houpa/" target="_blank">
            <i className="icon icon-linkedin" />
          </Link>
        </Social>
      </Container>
    </Back>
  );
};

export default HeaderB2B;
