import HeaderB2B from 'components/Header/B2B';
import FooterB2B from 'components/Footer/B2B';
import React, { useRef } from 'react';
import Accordion from 'components/Accordion/Accordion';
import ReactPlayer from 'react-player/youtube';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import useMedia from 'hooks/useMedia';

import {
  Duvidas,
  Gradiente,
  Inscricao,
  Participar,
  Plano,
  Pontuacao,
  Premio,
  Regras,
  Titulo,
  Form,
  Li,
  Geral,
} from './styles';

const Convida: React.FC = () => {
  const StoreRef = useRef(null);
  const InscRef = useRef(null);
  const PartRef = useRef(null);
  const PreRef = useRef(null);
  const RegRef = useRef(null);
  const QuestionRef = useRef(null);

  const FormHub = useHubspotForm({
    portalId: '6894615',
    formId: 'ab899482-ea97-4f61-89f6-698889b91a12',
    target: '#my-hubspot-form',
  });

  const scrollToRef = (ref: any) =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

  const color2 = 'purple';
  const color = 'white';

  const tablet = useMedia('(max-width: 1279px');
  const cor = tablet ? color : color2;

  return (
    <>
      <Geral>
        <HeaderB2B bgContainer={cor}>
          <Li>
            <button type="button" onClick={() => scrollToRef(StoreRef)}>
              Início
            </button>
          </Li>
          <Li>
            <button type="button" onClick={() => scrollToRef(InscRef)}>
              Inscreva-se
            </button>
          </Li>
          <Li>
            <button type="button" onClick={() => scrollToRef(PartRef)}>
              Como Participar
            </button>
          </Li>
          <Li>
            <button type="button" onClick={() => scrollToRef(PreRef)}>
              Prêmios
            </button>
          </Li>
          <Li>
            <button type="button" onClick={() => scrollToRef(RegRef)}>
              Regulamento
            </button>
          </Li>
          <Li>
            <button type="button" onClick={() => scrollToRef(QuestionRef)}>
              Dúvidas
            </button>
          </Li>
          <Li purple>
            <a
              href="https://negocios.houpa.app/download/empresas"
              target="_blank"
              rel="noreferrer">
              Baixe o APP
            </a>
          </Li>
        </HeaderB2B>
        <Titulo ref={StoreRef}>
          <h1>O houpa! Negócios ajuda sua marca a vender mais!</h1>
          <h3>
            Participe da promoção e ganhe 1 mês grátis do Plano Diamante houpa!
            Negócios
          </h3>
        </Titulo>
        <Gradiente>
          <div className="bannerConvida" />
          <Inscricao ref={InscRef}>
            <div className="orientacao">
              <h1>Faça a inscrição da sua marca e participe da promoção! </h1>
              <h3>Nossa equipe comercial entrará em contato.</h3>
              <Form>
                <div id="my-hubspot-form" />
              </Form>
            </div>

            <div className="inscricao" />
          </Inscricao>

          <Participar ref={PartRef}>
            <h1>Como participar</h1>
            <div className="celulares" />
            <div className="moedas" />
            <div className="ganhe">
              <h2>Ganhe</h2>
              <h1> 200 pontos</h1>
            </div>
            <h3>a cada primeira compra dos seus clientes convidados.*</h3>
            <h4 className="ganheh4">
              * Para multimarcas que se cadastraram no período da campanha e
              efetuaram uma compra.
            </h4>

            <div className="video">
              <ReactPlayer
                width="100%"
                url=" https://www.youtube.com/watch?v=yJbklA3addg"
                controls
              />
            </div>
            <h4 className="assista">Assista ao vídeo!</h4>
            <h1 className="conheca" ref={PreRef}>
              Conheça os prêmios!
            </h1>
            <Premio />
            <h2 className="Tplanos">
              Saiba mais sobre os planos do houpa! Negócios
            </h2>
            <Plano>
              <div className="quebra">
                <div className="planos">
                  <div className="orientationPlano">
                    <h1>Plano Diamante</h1>
                    <div className="border">
                      <h2>Crie alto impacto e relevância de marca!</h2>
                      <h3>
                        O plano DIAMANTE foi desenvolvido para marcas que
                        desejam utilizar todos os recursos do houpa! com alta
                        frequência e representatividade no aumento de vendas.
                      </h3>
                    </div>
                  </div>
                  <div className="numerox">
                    <p className="nx">
                      4x
                      <span className="textonx">
                        destaque na home do houpa! negócios
                      </span>
                    </p>

                    <p className="nx">
                      2x
                      <span className="textonx">
                        via Stories: Instagra e Facebook
                      </span>
                    </p>

                    <p className="nx">
                      2x
                      <span className="textonx">via E-mail Marketing</span>
                    </p>

                    <p className="nx">
                      2x
                      <span className="textonx">via Feed do houpa!</span>
                    </p>

                    <p className="nx">
                      2x
                      <span className="textonx">
                        ações de venda customizáveis
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="espaco">
                <div className="planos">
                  <div className="orientationPlano">
                    <h1>Plano Super</h1>
                    <div className="border">
                      <h2>Crie relevância para seu negócio!</h2>
                      <h3>
                        O plano SUPER é perfeito para aumentar as vendas e
                        promover alcance e conhecimento da marca em múltiplos
                        canais.
                      </h3>
                    </div>
                  </div>
                  <div className="numerox">
                    <p className="nx">
                      2x
                      <span className="textonx">
                        destaque na home do houpa! negócios
                      </span>
                    </p>

                    <p className="nx">
                      1x
                      <span className="textonx">
                        via Stories: Instagra e Facebook
                      </span>
                    </p>

                    <p className="nx">
                      1x
                      <span className="textonx">via E-mail Marketing</span>
                    </p>

                    <p className="nx">
                      1x
                      <span className="textonx">via Feed do houpa!</span>
                    </p>

                    <p className="nx">
                      1x
                      <span className="textonx">via notificação PUSH</span>
                    </p>
                  </div>
                </div>
              </div>
            </Plano>
            <Pontuacao>
              <h1>Fique de olho na sua pontuação!</h1>
              <h3 className="aviso">Ainda não há marcas cadastradas!</h3>
              {/* <div className="ranking">
              <h1>1° lugar</h1>
              <h1>Unique Chic</h1>
              <h1>1200 pts</h1>
            </div>

            <div className="ranking">
              <h1>2° lugar</h1>
              <h1>Namine</h1>
              <h1>1200 pts</h1>
            </div>

            <div className="ranking">
              <h1>3° lugar</h1>
              <h1>La Folie</h1>
              <h1>1200 pts</h1>
            </div>

            <div className="ranking">
              <h1>4° lugar</h1>
              <h1>Tifa</h1>
              <h1>1200 pts</h1>
            </div>

            <div className="ranking">
              <h1>5° lugar</h1>
              <h1>Noir Deux</h1>
              <h1>1200 pts</h1>
            </div> */}
              <h2>Você pode juntar pontos até o dia 31 de março. Aproveite!</h2>
              <h3>
                Pontuação válida somente para Clientes novos X Novos pedidos.
              </h3>
            </Pontuacao>
            <Duvidas ref={QuestionRef}>
              <div className="flex">
                <div className="frequente">
                  <h1>Dúvidas Frequentes</h1>
                  <img
                    src="http://assets.houpa.app/images/houpa_hotsite/convida/duvidas.png"
                    alt=""
                  />
                </div>

                <div className="perguntas">
                  <Accordion
                    title="Preciso ter o houpapago para participar?"
                    content="Sim. É necessário ter o houpa!pago ativo para participar da campanha, oferecendo boleto à vista e/ou cartão de crédito.
                  "
                  />
                  <Accordion
                    title="Até quando é possível se inscrever para participar da campanha?"
                    content="A data limite para se inscrever é até 10/03/2021. "
                  />
                  <Accordion
                    title="Como funcionará a premiação?"
                    content="Após o término da campanha, os pontos serão contabilizados e averiguados para que as 3 marcas ganhadoras sejam anunciadas. A equipe comercial entrará em contato para comunicar o prêmio. 
                  "
                  />
                  <Accordion
                    title="Como faço para concorrer aos prêmios?"
                    content="Para concorrer, basta fazer sua inscrição no formulário da página da promoção e convidar seus clientes para baixar e se cadastrar no houpa! Negócios. 
                  "
                  />
                  <Accordion
                    title="Como posso acumular mais pontos?"
                    content="Para concorrer, basta fazer sua inscrição no formulário da página da promoção e convidar seus clientes para baixar e se cadastrar no houpa! Negócios. 
                  "
                  />
                  <Accordion
                    title="A promoção é válida para novas marcas na plataforma?"
                    content=" Sim. Válido para todas as marcas que se inscreverem durante o período da promoção."
                  />
                </div>
              </div>
            </Duvidas>

            <Regras>
              <p ref={RegRef}>
                Para participar da ação, que acontecerá de 03/03/2021 a
                31/03/2021, é obrigatório que a marca esteja cadastrada na
                plataforma e tenha o houpa!pago ativo (cartão de crédito e/ou
                boleto à vista). Todas as marcas cadastradas no houpa! poderão
                fazer a inscrição para participar até o dia 10/03/2021. Ganharão
                as três marcas que receberem mais pedidos de clientes recém
                cadastrados no houpa! durante o período da campanha. A marca que
                ganhar em primeiro lugar, receberá um mês do plano Diamante,
                grátis. As marcas que ficarem em segundo e terceiro lugar,
                ganharão um mês do plano Super. A apuração do resultado será
                feita em 5 dias úteis após o fechamento da ação e as marcas
                ganhadoras serão divulgadas no hotsite e por meio da equipe
                comercial do houpa!. O houpa! se reserva no direito de apurar e
                conferir a legitimidade das vendas junto às marcas e as
                multimarcas. O descumprimento desses termos, resultará na
                desclassificação da marca na campanha.
              </p>
            </Regras>
            <FooterB2B />
          </Participar>
        </Gradiente>
      </Geral>
    </>
  );
};

export default Convida;
