import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import RoutesWrapper from './Routes';
import GlobalStyles from './styles/GlobalStyles';

import './styles/icons.css';
import './styles/fonts-config.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <RoutesWrapper />
    </BrowserRouter>
  );
};

export default App;
