import React from 'react';

import { Back, Container, Logo, Link, Row, Col } from './styles';

type bg = 'purple' | 'white';
interface Props {
  bgContainer?: bg;
  site?: boolean;
}

const FooterB2B: React.FC<Props> = ({
  bgContainer = 'purple',
  site = true,
}) => {
  return (
    <Back bgContainer={bgContainer}>
      <Container className="start-md" bgContainer={bgContainer}>
        <Col>
          <Link href="https://negocios.houpa.app" bgContainer={bgContainer}>
            <Logo
              bgContainer={bgContainer}
              className="icon icon-logo-houpa-business"
            />
          </Link>

          <div className="no-lg address">
            Rua Professor Cesare Lombroso, 305, Cj 6 - 3º andar, Bom Retiro -
            São Paulo/SP - (11) 9 7288-3125
          </div>

          <div className="no-lg external-link">
            <ul>
              <li>Política de privacidade</li>
              <li>Termos de uso</li>
              <li>Anuncie</li>
            </ul>
          </div>
        </Col>

        <Col>
          <Row className="end column-md">
            <Col className="stores-column">
              <p>Compre pelo app do houpa! Negócios</p>
              <Row className="stores column-md">
                <Link
                  href="https://negocios.houpa.app/download/empresas"
                  target="_bank"
                  bgContainer={bgContainer}>
                  <i className="icon icon-app-store" />
                </Link>
                <Link
                  href="https://negocios.houpa.app/download/empresas"
                  target="_bank"
                  bgContainer={bgContainer}>
                  <i className="icon icon-google-play" />
                </Link>
              </Row>
            </Col>

            <Col>
              <p>Siga-nos nas redes sociais</p>
              <Row>
                <Link
                  href="https://www.instagram.com/houpa.app/"
                  target="_blank"
                  bgContainer={bgContainer}>
                  <i className="icon icon-instagram" />
                </Link>
                <Link
                  href="https://www.facebook.com/houpaapp/"
                  target="_blank"
                  bgContainer={bgContainer}>
                  <i className="icon icon-facebook" />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/houpa/"
                  target="_blank"
                  bgContainer={bgContainer}>
                  <i className="icon icon-linkedin" />
                </Link>
              </Row>
            </Col>
            {site && (
              <Col>
                <p>Acesse nosso site</p>
                <Row className="link-text">
                  <Link
                    href="http://b2b.houpa.app"
                    target="_blank"
                    bgContainer={bgContainer}>
                    www.b2b.houpa.app
                  </Link>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Container>
    </Back>
  );
};

export default FooterB2B;
